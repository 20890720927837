"use client";
import React from "react";
import { useRouter } from "next/navigation";
import Image from "next/image";

import img from "@/assets/FourBanner.png";
import star from "@/assets/star.svg";
import lightning from "@/assets/lightning.svg";

const Four = () => {
  const router = useRouter();
  return (
    <>
      <div className="sm:px-4 lg:px-[64px] xl:px-[108px] py-12 md:py-[64px] flex flex-col gap-9 md:gap-12 lg:gap-[64px]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[64px]">
          <div className="flex flex-col my-0 md:my-auto gap-8 md:gap-10">
            <h1 className=" text-third text-[24px] xs:text-[32px] lg:text-[36px] font-semibold">
              Nâng tầm cuộc chơi với Siêu Thích và Tăng tốc
            </h1>

            <div>
              <div className="flex justify-start gap-3">
                <div className="h-7 w-7 flex flex-shrink-0">
                  <Image src={star} alt="star" height={28} width={28} />
                </div>

                <span className="text-secondary text-[18px]">
                  Gia tăng cơ hội tương hợp gấp 3x và giữ cuộc trò chuyện lâu hơn 70%.
                </span>
              </div>

              <div className="flex justify-start gap-3">
                <div className="h-7 w-7 flex flex-shrink-0">
                  <Image src={lightning} alt="lightning" height={28} width={28} />
                </div>
                <span className="text-secondary text-[18px]">
                  Nhận thêm tới 10 lần lượt xem hồ sơ chỉ bằng một lần nhấn nút — tối đa hóa thời gian và tiềm năng
                  tương hợp của bạn.
                </span>
              </div>
            </div>

            <div onClick={() => router.push("/tai-xuong")} className="btn-primary h-[56px] w-[205px]">
              Tải xuống Teklove
            </div>
          </div>

          <div className="flex justify-center ">
            <Image src={img} alt="banner" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Four;
