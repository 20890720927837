"use client";
import React from "react";
import { useRouter } from "next/navigation";
import Image from "next/image";

import bg from "@/assets/FirstBg.png";
import img from "@/assets/FirstBanner.png";

const First = () => {
  const router = useRouter();

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(255, 41, 95, 0.85), rgba(255, 153, 84, 0.85)), url(${bg.src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "calc(100vh - 80px)",
        width: "100%",
        overflow: "hidden",
      }}
      className="flex flex-col md:justify-between md:flex-col"
    >
      <div className="flex flex-col justify-center items-center flex-grow gap-12 pt-[80px] px-6 md:justify-start md:items-start">
        <div className="flex flex-col gap-4 mx-auto text-center">
          <div className="text-[#fff] bg-[#FFFFFF1A] text-[14px] rounded-2xl py-1 px-3 border-[1px] border-[#FFFFFF33] backdrop-blur-2xl w-[77px] mx-auto">
            Teklove
          </div>

          <h1 className="text-white font-semibold text-[36px] md:text-[48px] lg:text-[52px] xl:text-[60px]">
            Môi trường gặp gỡ tương hợp tốt <br className="hidden lg:block" />
            nhất tiếp theo của bạn.
          </h1>

          <span className="text-[#FFFFFFCC] text-[18px] md:text-[20px]">
            Với Teklove, ứng dụng hẹn hò miễn phí hàng đầu Việt Nam, hàng triệu người độc{" "}
            <br className="hidden lg:block" />
            thân tuyệt vời khác luôn nằm trong tầm tay bạn.
          </span>

          <div
            onClick={() => router.push("/tai-xuong")}
            className="w-[141px] h-[56px] rounded-full bg-[#F4F4F4] flex justify-center items-center mx-auto cursor-pointer hover:opacity-95"
          >
            Tải xuống
          </div>
        </div>

        <div className="mx-auto hidden md:block">
          <Image src={img} alt="First Banner" />
        </div>
      </div>
    </div>
  );
};

export default First;
