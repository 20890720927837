"use client";
import React from "react";
import Image from "next/image";

import checked from "@/assets/Check icon.svg";
import bg1 from "@/assets/SecondBanner1.png";
import bg2 from "@/assets/SecondBanner2.png";

const Second = () => {
  return (
    <>
      <div className="sm:pl-4 md:pl-8 lg:pl-[64px] xl:pl-[108px] py-12 md:py-[64px] flex flex-col gap-9 md:gap-12 lg:gap-[64px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[64px]">
          <div className="flex flex-col my-0 md:my-auto">
            <h1 className=" text-third text-[24px] xs:text-[30px] font-semibold mb-4 md:mb-6">
              Mọi khởi đầu đều đến từ Teklove
            </h1>
            <span className="text-secondary text-[16px] md:text-[18px] mb-6 md:mb-8">
              Xem điều gì xảy ra khi bạn tỏ ý thích một người với tính năng Quẹt Phải đặc trưng của chúng tôi.
            </span>
            <div className="pl-4 flex flex-col gap-5">
              <div className="flex gap-3 items-center">
                <Image src={checked} alt="checked" height={28} width={28} className="flex flex-shrink-0 h-7 w-7" />
                <span className="text-secondary text-[16px] md:text-[18px]">
                  Tìm kiếm tương hợp chỉ với một cái quẹt phải
                </span>
              </div>

              <div className="flex gap-3 items-center">
                <Image src={checked} alt="checked" height={28} width={28} className="flex flex-shrink-0 h-7 w-7" />
                <span className="text-secondary text-[16px] md:text-[18px]">
                  Nhắn tin trò chuyện ngay trong ứng dụng
                </span>
              </div>

              <div className="flex gap-3  items-center">
                <Image src={checked} alt="checked" height={28} width={28} className="flex flex-shrink-0 h-7 w-7" />
                <span className="text-secondary text-[16px] md:text-[18px]">
                  Tự động hóa việc hỗ trợ người dùng và kết nối nhanh hơn với đối tượng phù hợp.
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-end ">
            <Image src={bg1} alt="bg1" />
          </div>
        </div>
      </div>

      <div className="sm:pr-4 lg:pr-[64px] xl:pr-[108px] pb-12 md:pb-[64px] flex flex-col gap-9 md:gap-12 lg:gap-[64px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[64px]">
          <Image src={bg2} alt="bg2" className="hidden lg:block" />

          <div className="flex flex-col my-0 md:my-auto ml-4 md:ml-8 lg:ml-0">
            <h1 className=" text-third text-[24px] xs:text-[30px] font-semibold mb-4 md:mb-6">
              Lưu trữ mọi khoảng khắc của bạn
            </h1>
            <span className="text-secondary text-[16px] md:text-[18px] mb-6 md:mb-8">
              Sau khi giúp bạn tìm được tương hợp của mình, chúng tôi có gì cho bạn nè!
            </span>

            <div className="pl-4 flex flex-col gap-5">
              <div className="flex gap-3 items-center">
                <Image src={checked} alt="checked" height={28} width={28} className="flex flex-shrink-0 h-7 w-7" />
                <span className="text-secondary text-[16px] md:text-[18px]">Bộ đếm ngày bên nhau sau khi hẹn hò</span>
              </div>

              <div className="flex gap-3 items-center">
                <Image src={checked} alt="checked" height={28} width={28} className="flex flex-shrink-0 h-7 w-7" />
                <span className="text-secondary text-[16px] md:text-[18px]">
                  Lưu trữ kỷ niệm của bạn thông qua nhật ký tình yêu
                </span>
              </div>

              <div className="flex gap-3  items-center">
                <Image src={checked} alt="checked" height={28} width={28} className="flex flex-shrink-0 h-7 w-7" />
                <span className="text-secondary text-[16px] md:text-[18px]">
                  Nhiều phần quà khác dành riêng cho đôi bạn
                </span>
              </div>
            </div>
          </div>

          <Image src={bg2} alt="bg2" className="block lg:hidden" />
        </div>
      </div>
    </>
  );
};

export default Second;
