"use client";
import React from "react";
import { useRouter } from "next/navigation";
import Card from "./Card";

import icon1 from "@/assets/emoji-happy.svg";
import icon2 from "@/assets/globe.svg";
import icon3 from "@/assets/people.svg";
import icon4 from "@/assets/HandFist.svg";
import icon5 from "@/assets/clock.svg";
import icon6 from "@/assets/Infinity.svg";

const Three = () => {
  const router = useRouter();

  return (
    <div className="sm:px-4 lg:px-[64px] xl:px-[108px] py-12 md:py-[64px] flex flex-col gap-9 md:gap-12 lg:gap-[64px] bg-[#FFF2F6]">
      <div>
        <h1 className="text-[24px] xs:text-[30px] lg:text-[36px] font-semibold text-center mb-4 md:mb-8">
          Tại sao bạn nên chọn ứng dụng hẹn hò Teklove
        </h1>
        <p className="text-secondary w-auto text-center md:w-[700px] lg:w-[806px] text-[18px] md:text-[20px] mx-auto">
          Dù bạn đang muốn tìm người yêu, bạn hẹn hay chỉ đơn giản là tìm người kết nối trò chuyện, bạn vẫn muốn tìm ứng
          dụng phù hợp nhất cho mình. Với Teklove, hẹn hò qua mạng từ nay còn dễ hơn nữa!
        </p>
      </div>

      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8">
        <Card
          img={icon1}
          title="Khám phá sở thích của bạn"
          desc='Thật dễ dàng trò chuyện với bạn tương hợp khi biết rằng hai bên có chung một vài sở thích. Ứng dụng Tinder cho phép bạn thêm Sở thích vào hồ sơ của mình và kết nối với những người cùng chung "chí hướng".'
        />
        <Card
          img={icon2}
          title="Cùng chúng tôi chu du khắp thiên hạ"
          desc='Khi bạn muốn gặp gỡ người bản địa thì dù bạn đang ở bất kỳ thành phố nào trên thế giới, chat với mọi người "dù gần nhà hay xa ngõ" qua các tính năng cho phép bạn nối dây "tơ hồng" với bất kỳ ai ở bất kỳ đâu trên thế giới này.'
        />
        <Card
          img={icon3}
          title="Kết nối với mọi người khắp nơi"
          desc="Vượt ra ngoài thiết đặt khoảng cách của bạn và chat với mọi người trên khắp địa cầu cùng tính năng Hộ Chiếu. Bạn có thể kết nối tương hợp với mọi người khắp nơi từ bất kỳ đâu trên thế giới."
        />
        <Card
          img={icon4}
          title="Kết nối tương hợp ngay trong tầm tay"
          desc='Gặp gỡ mọi người trên mạng là cả một hành trình, thứ bạn muốn chính là "ai đó" đáng tin cậy cùng bạn đồng hành trong suốt hành trình ấy. Nói đến chuyện tìm bạn tương hợp, Tinder có thể đảm đương vị trí hoa tiêu đồng hành cùng bạn.'
        />
        <Card
          img={icon5}
          title="Trọn gói, Mọi lúc Mọi nơi"
          desc="Chúng tôi tin mỗi người đều có quyền được nhìn nhận và tự mình quyết định bước tiến đầu tiên bất kể nhân dạng của họ ra sao. Chúng tôi luôn ưu tiên giới thiệu mọi người với bạn và để bạn có toàn quyền lựa chọn đối tượng mình muốn cùng trò chuyện."
        />
        <Card
          img={icon6}
          title="Khả năng vô hạn"
          desc='Chúng tôi không phải "tay mơ" trong việc giúp các bạn "độc thân vui tính" xích lại gần nhau hơn qua mạng. Chúng tôi sẵn sàng mang đến trải nghiệm và vô số khả năng đáp ứng mục tiêu của bạn, bất kể điều đó là gì.'
        />
      </div>
      <div onClick={() => router.push("/tai-xuong")} className="btn-primary h-[56px] w-[205px] mx-auto">
        Tải xuống Teklove
      </div>
    </div>
  );
};

export default Three;
