import React from "react";

const Five = () => {
  return (
    <div className="sm:px-4 lg:px-[64px] xl:px-[108px] py-12 md:py-[64px] flex flex-col gap-9 md:gap-12 lg:gap-[64px] bg-[#FFF2F6]">
      <h1 className="text-third text-center font-medium text-[24px] md:text-[32px]">
        Tôi đã nghĩ sẽ chẳng có gì nghiêm túc, nhưng hôm đó tôi đã thấy mỹ nhân này trên Teklove. Tôi đã Siêu Thích cô
        ấy, chính xác là đã chi thêm tiền để có lượt Siêu Thích đó!
      </h1>

      <div className="text-center flex flex-col gap-4">
        <span className=" text-third text-[18px] font-medium">Nguyễn Văn A</span>
        <span className="text-[#44474D] text-[14px]">Khách hàng</span>
      </div>
    </div>
  );
};

export default Five;
