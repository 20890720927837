"use client";
import First from "@/modules/Home/First";
import Second from "@/modules/Home/Second";
import Three from "@/modules/Home/Three";
import Four from "@/modules/Home/Four";
import Five from "@/modules/Home/Five";

export default function Home() {
  return (
    <>
      <First />
      <Second />
      <Three />
      <Four />
      <Five />
    </>
  );
}
